<template>
  <div class="briefintroduction-container">
    <div class="briefintroduction-top">
      <video src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/video/guanwangzhengtou.mp4" loop="loop" muted="muted" autoplay>
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div class="briefintroduction-zhong">
      <div class="briefintroduction-one">
        <div class="zhong-title con-title">项目概况</div>
        <div class="zhong-text text-con">
          <p class="con-p">
            郑投科技创新园是由郑州市产业经营类投融资平台郑州投资控股有限公司的国有全资子公司-郑州投控科技产业园区发展有限公司出资建设，园区整合信息、资源、运营、服务等优势为“智造”产业赋能，致力于打造以跨境贸易为引领的河南智能制造及数字信息技术制造的产业生态综合体。
          </p>
          <br>
          <p class="con-p">
            园区依托郑州市及二七经济技术开发区产业发展优势，以智能制造、数字信息技术制造等为核心动力，提供创业场地、研发共享、技术支撑、政策咨询、投资融资、资源对接等服务、营造“龙头凝聚、平台多元、投资活跃、活动丰富”的产业发展环境，形成“主体多元、开放协同、特色鲜明”的智能制造、数字经济产业生态圈。
          </p>
          <br>
          <p class="con-p">
            郑州科技创新园位于郑州市西南部二七经济技术开发区，公明路与东方路交叉口，项目占地136.614亩，总建筑面积约27万平方米，总投资额约15亿元，一期45.6亩，总建筑面积9.5万平方米，建设内容为300~5000㎡花园式产业办公研发总部，二期占地91.014亩，规划总建筑面积17.5万平方米，预计2023年建设完成。
          </p>
          <br>
          <p class="con-p">
            项目紧邻西四环和郑少高速，距离郑州市政府直线距离约9公里，距离凤湖智能新区板块直线距离在2公里之内，距离郑州国际机场45分钟车程，距离地铁六号线安宁站1.5公里。
          </p>
        </div>
        <div class="zhong-img con-img" style="padding-bottom: 40px">
          <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/gaikuang.png" />
        </div>
      </div>
      <div class="briefintroduction-two" style="padding-bottom: 40px">
        <div class="zhong-title con-title">智慧园区</div>
        <div class="zhong-text text-con">
          <p class="con-p">
            园区内部署5G网络，实现低延迟、高速度、大流量的数据传输，相比传统的WiFi网络，建设成本更低，无需铺设网线，更安全、更方便的部署大量智能设备。通过智慧园区综合管理系统，从硬体到软体全面升级、构建园区与用户美好连接，实现智慧园区、科技化物业、社区化运营，依托人工智能、大数据、物联网、机器人等前沿技术连接人、物、服务，建设园区信息基础设施和公共服务体系，打造安全、便捷、节能、舒适的新型智慧园区，将极大的赋能入驻企业，实现园区与产业发展、城市化管理的高度融合。
          </p>
        </div>
        <div class="zhong-img con-img">
          <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/yuanqu.png" />
        </div>
      </div>
    </div>
    <div class="briefintroduction-bottom">
      <div class="con-title">合作伙伴</div>
      <div class="bottom-tag">
        <div>
          <img src="../../assets/images/BriefIntroduction/zihui.png" />
        </div>
        <div>
          <img src="../../assets/images/BriefIntroduction/yuzi.png" />
        </div>
        <div>
          <img src="../../assets/images/BriefIntroduction/toukong.png" />
        </div>
        <div>
          <img src="../../assets/images/BriefIntroduction/erqi1.png" />
        </div>
        <div>
          <img src="../../assets/images/BriefIntroduction/guoji.png" />
        </div>
        <div>
          <img src="../../assets/images/BriefIntroduction/guajing.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.briefintroduction-top{
   width: 100%;
}
.briefintroduction-top video {
  width: 100%;

}
.briefintroduction-one {
  background: rgba(242, 245, 248, 1);
}
.briefintroduction-two {
  background: rgba(255, 255, 255, 1);
}
.briefintroduction-bottom {

  background: rgba(242, 245, 248, 1);
  opacity: 1;
}
.bottom-tag {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1520px;
  margin: 0 auto;
  padding-bottom: 25px;
}
.bottom-tag div {
  width: 450px;
  height: 150px;
  margin: 0 20px;
}
.bottom-tag div img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width:1500px) {
  .con-img{
    margin-left: 40px;
  }
}
</style>
